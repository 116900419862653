<template>
  <v-container fluid>
    <v-layout
      id="changePassword"
      row
      wrap
      d-block
    >
      <v-flex xs12>
        <h3 class="headline">
          Cambiar contraseña
        </h3>
      </v-flex>
      <v-spacer />
      <v-flex
        row
        d-flex
        xs12
      >
        <v-form>
          <v-flex xs12>
            <v-text-field
              v-model="currentPass"
              name="passwordold"
              label="Contraseña actual"
              type="password"
              :autocomplete="false"
              :error-messages="oldpasswordErrors"
              @input="$v.currentPass.$touch()"
              @blur="$v.currentPass.$touch()"
            />
          </v-flex>
          <v-layout
            row
            d-flex
          >
            <v-flex
              xs12
              md6
            >
              <v-text-field
                v-model="newPass"
                name="passwordnew"
                label="Contraseña nueva"
                type="password"
                :autocomplete="false"
                :error-messages="newpasswordErrors"
                @input="$v.newPass.$touch()"
                @blur="$v.newPass.$touch()"
              />
            </v-flex>
            <v-spacer />
            <v-flex
              xs12
              md6
            >
              <v-text-field
                v-model="newPassConf"
                name="passwordconf"
                label="Confirmar contraseña"
                type="password"
                :autocomplete="false"
                :error-messages="confpasswordErrors"
                @input="$v.newPassConf.$touch()"
                @blur="$v.newPassConf.$touch()"
              />
            </v-flex>
          </v-layout>
          <v-layout
            row
            d-flex
            align-content-start
          >
            <v-flex
              xs4
              mt-4
            >
              <v-btn
                dark
                color="primary"
                type="submit"
                @click.prevent="commitChange"
              >
                Cambiar Contraseña
              </v-btn>
            </v-flex>
          </v-layout>
        </v-form>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import { validationMixin } from 'vuelidate';
import {
  required, sameAs, minLength, not,
} from 'vuelidate/lib/validators';
import axios from 'axios';
import JWTConfigMixin from '@/components/mixins/JWTConfigMixin.vue';

export default {
  mixins: [validationMixin, JWTConfigMixin],

  data() {
    return {
      currentPass: '',
      newPass: '',
      newPassConf: '',
    };
  },

  validations: {
    currentPass: { required, minLength: minLength(8) },
    newPass: { required, minLength: minLength(8), differentAsCurrent: not(sameAs('currentPass')) },
    newPassConf: { sameAsPassword: sameAs('newPass') },
  },

  computed: {
    oldpasswordErrors() {
      const errors = [];
      if (!this.$v.currentPass.$dirty) return errors;
      if (!this.$v.currentPass.required) errors.push('Campo requerido.');
      if (!this.$v.currentPass.minLength) errors.push('La contraseña debe contener al menos 8 caracteres.');
      console.log('data', errors);
      return errors;
    },
    newpasswordErrors() {
      const errors = [];
      if (!this.$v.newPass.$dirty) return errors;
      if (!this.$v.newPass.required) errors.push('Campo requerido.');
      if (!this.$v.newPass.minLength) errors.push('La contraseña debe contener al menos 8 caracteres.');
      if (!this.$v.newPass.differentAsCurrent) errors.push('La contraseña debe ser distinta a la actual.');
      return errors;
    },
    confpasswordErrors() {
      const errors = [];
      if (!this.$v.newPass.$dirty) return errors;
      if (!this.$v.newPassConf.sameAsPassword) errors.push('La contraseñas no coinciden.');
      return errors;
    },
  },

  methods: {
    commitChange() {
      this.$store.commit('adminSnackbarDown');
      if (this.$v.$invalid) {
        this.$store.commit('adminSnackbarUp', 'Verifique que los datos del formulario sean correctos.');
        return;
      }

      const payload = {
        old_password: this.currentPass,
        new_password: this.newPass,
      };
      this.$store.commit('setLoading');
      axios.put(this.$store.state.endpoints.offers.admin.changePassword, payload, this.jwtConfig)
        .then(resp => {
          this.$store.commit('adminSnackbarUp', resp.data.response);
          this.$store.commit('unsetLoading');
        })
        .catch(err => {
          this.$store.commit('adminSnackbarUp', err.response.data.response);
          this.$store.commit('unsetLoading');
        });
    },
  },

};
</script>

<style scoped>
  #changePassword {
    max-width: 400px;
  }
</style>
